<template>
<div class="home-bg">
    <div class="home-content">
        <div class="banner-box">
            <!-- <img class="prime-img" :src="primeImgSrc" @onerror="handleImgError(this)"/> -->
            <h1 class="page-title">引领智能应用的未来<br>创造未来卓越的数字生活</h1>
            <!-- <h1 class="page-title"></h1> -->
            <div class="tab-box">
                <!-- <div class="tab-blur"></div> -->
                <div class="tab-item" v-for="(item,index) in tabList" :key="index">{{ item }}</div>
            </div>
        </div>
        <div class="label-card-box">
            <div class="label-card" v-for="(item,index) in labelList" :key="index">
                <div class="label-card-top-box">
                    <img class="label-card-icon" :src="item.labelIcon"/>
                    <div class="label-card-text-box">
                        <img class="label-title-icon" :src="item.labelTextIcon"/>
                        <div class="label-card-title">{{ item.labelTitle }}</div>
                    </div>
                </div>
                <div class="label-text">{{ item.labelText }}</div>
            </div>
        </div>
        <div class="app-card-box">
            <div class="app-card" v-for="(item,index) in appList" :key="index">
                <div class="app-card-top-box">
                    <img class="app-icon" :src="item.appIcon"/>
                    <div class="app-title-box">
                        <div class="app-title-top-box">
                            <div class="app-title">{{ item.appTitle }}</div>
                            <div class="app-type">{{ item.appType }}</div>
                        </div>
                        <div class="app-subtitle">{{ item.appSubtitle }}</div>
                    </div>
                    <div class="download-btn">
                        <img class="download-icon" src="../../assets/download-icon.png" />
                        <div class="download-text" @click="openURL(item.url)">体验魔法</div>
                    </div>
                    <img class="right-arrow" src="../../assets/right-arrow.png" />
                </div>
                <div class="app-desc">{{ item.appDesc }}</div>
                <img class="app-img" :src="item.appImg"/>
            </div>
        </div>
    </div>
  <div class="home-content-footer">
    <div class="copyright">联系我们</div>
    <div class="copyright">联系方式：<a class='home-content-footer-link' href="mailto:huweitech@hweitech.com">huweitech@hweitech.com</a></div>
    <div class="copyright">版权所有©2023 虎薇科技有限公司</div>
  </div>
</div>
</template>
<script>

export default {
    data() {
        return {
            primeImgSrc: require('../../assets/prime-img.png'),
            tabList: ['使命感','创新','科技','人工智能','开放合作'],
            labelList: [{
                labelTitle: '使命与愿景',
                labelText: '公司愿景是成为全球领先的人工智能公司，为人们提供创新、独特的体验，重新定义人与信息之间的连接。我们相信通过将人工智能与生活方方面面相结合，可以为用户创造显著的价值。',
                labelIcon: require('../../assets/label-icon1.png'),
                labelTextIcon: require('../../assets/label-text-icon1.png')
            },{
                labelTitle: '核心价值',
                labelText: '公司拥有世界一流的科学家、工程师和设计师，我们的使命是利用人工智能技术改善人们的生活与工作体验，打造一个更加智能和联系紧密的世界。我们坚信，通过公司科技的努力和创新，我们可以为用户带来真正有意义和积极影响的体验。',
                labelIcon: require('../../assets/label-icon2.png'),
                labelTextIcon: require('../../assets/label-text-icon2.png')
            },{
                labelTitle: '我们的产品',
                labelText: '',
                labelIcon: require('../../assets/label-icon3.png'),
                labelTextIcon: require('../../assets/label-text-icon3.png')
            },],
            appList: [{
                appIcon: require('../../assets/shaso.png'),
                appTitle: 'SHASO',
                appType: '社交',
                appSubtitle: '基于人工智能开发新型社交产品',
                appDesc: 'SHASO是基于大语言模型与人工智能开发的社交产品，我们创新了群聊语音助手与切换语气聊天功能，使用户在SHASO上的交流更多乐趣与便捷。',
                appImg: require('../../assets/app-img1.png')
            },{
                appIcon: require('../../assets/ts_hiring.png'),
                appTitle: '招聘Agent',
                appType: '招聘',
                appSubtitle: '自动化招聘助手',
                appDesc: '招聘Agent是一款专注于招聘领域的浏览器插件。招聘筛选流程不仅可以自动化进行，还使您能够更高效地管理招聘任务。智能算法为您推荐符合要求的候选人。',
                appImg: require('../../assets/app-img2.png'),
                url: 'https://download.shasoapp.com/tshiring/tsHring-win-x64.exe'
            },{
              appIcon: require('../../assets/ts_dance_ai.png'),
              appTitle: '灵魂舞王',
              appType: '娱乐',
              appSubtitle: '上传照片AI生成动态舞蹈视频',
              appDesc: '让你的照片跳动起来！用户通过上传自己的照片，选择舞蹈模版，即刻生成个性化的动态舞蹈视频，让每个人都能享受独特的数字舞蹈体验！',
              appImg: require('../../assets/app-img-dance-ai.png')
            },{
              appIcon: require('../../assets/ts_browse_ai.png'),
              appTitle: 'Browse AI',
              appType: '效率',
              appSubtitle: '你的人工智能助手',
              appDesc: 'Browse AI能够回溯你在手机上浏览过的内容，并提供详尽答复。无需繁琐搜索，只需提出问题，Browse AI即刻为你呈现相关信息，节省时间，提升效率。',
              appImg: require('../../assets/app-img-browse-ai.png')
            },{
              appIcon: require('../../assets/ts_keyboard_ai.png'),
              appTitle: 'Keyboard AI',
              appType: '效率',
              appSubtitle: '一体式智能键盘',
              appDesc: '一体式智能键盘，满足你的日常需求。无论是文字输入、智能推荐、多语言翻译，还是转换语气等，Keyboard AI都为你提供出色的体验。',
              appImg: require('../../assets/app-img-keyboard-ai.png')
            }
            ]
        }
    },
    methods: {
        handleImgError(image){
            image.onerror = null; // 防止无限循环
            image.style.display = 'none'; // 隐藏损坏的图片
        },
        openURL(url){
            if(url){
                window.location.href = url
            }
        }
    },
    // mounted() {
    //     setTimeout(() => {
    //         this.primeImgSrc = require('../../assets/prime-img.png')
    //     }, 2000)
    // },
}
</script>
<style scoped>
.home-bg{
    background-color: #1A1A1A;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}
.home-content{
    width: 1200px;
    overflow: hidden;
    margin: 60px 0;
}
.banner-box{
    width: 1200px;
    height: 751px;
    position: relative;
    background-image: url("../../assets/prime-img.png");
}
.prime-img{
    width: 1200px;
    height: 751px;
    background-color: #1A1A1A;
    position: absolute;
}
.prime-img::before{
    display: block;
    content: "";
    width: 1200px;
    height: 751px;
    background-color: #1A1A1A;
}
.page-title{
    font-family: PingFang HK;
    font-size: 3.75rem;
    margin: 0;
    font-weight: 200;
    line-height: 90px;
    text-align: left;
    color: #FFFFFF;
    padding: 60px;
}
.tab-box{
    padding: 16px 24px;
    border-radius: 16px;
    border: solid 2px #2C2C2E;
    display: inline-flex;
    margin-left: 60px;
}
.tab-blur{
    height: 100%;
    width: 100%;
    filter: blur(2px);
}
.tab-item{
    border-radius: 12px;
    background-color: #262626;
    color: #FFFFFF;
    padding: 16px 20px;
    margin-right: 32px;
}
.tab-item:last-child{
    margin-right: 0;
}
.label-card-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 90px;
}
.label-card{
    max-width: 560px;
    margin-bottom: 90px;
}
.label-card-top-box{
    display: flex;
    align-items: center;
}
.label-card-icon{
    height: 80px;
}
.label-card-text-box{
    position: relative;
    margin-left: 34px;
}
.label-title-icon{
    height: 63px;
}
.label-card-title{
    font-weight: 400;
    font-size: 30px;
    color: #FFFFFF;
    position: absolute;
    bottom: 0;
}
.label-text{
    color: #98989A;
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 28px;
}
.app-card-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.app-card{
    max-width: 560px;
    margin-bottom: 90px;
}
.app-card:nth-last-child(-n+2){
    margin-bottom: 36px;
}
.app-card-top-box{
    display: flex;
    align-items: center;
}
.app-title-box{
    margin-left: 20px;
    flex: 1;
}
.app-title-top-box{
    display: flex;
    align-items: center;
    margin-bottom: 8.5px
}
.app-title{
    color: #81FF00;
    font-size: 26px;
    font-weight: 600;
}
.app-type{
    border: 0.5px solid #81FF00;
    border-radius: 8px;
    padding: 6px 11px;
    color: #81FF00;
    margin-left: 16px;
}
.app-subtitle{
    color: #98989A;
    font-size: 15px;
}
.download-btn{
    display: flex;
    padding: 18px 24px;
    background: #2A2A2A;
    border-radius: 12px;
    cursor: pointer;
}
.right-arrow{
    display: none;
}
.download-text{
    color: #81FF00;
}
.app-desc{
    color: #98989A;
    font-size: 15px;
    font-weight: 400;
    margin-top: 20px;
}
.app-img{
    margin-top: 32px;
    width: 100%;
}
.home-content-footer {
  width: 1200px;
  overflow: hidden;
}
.home-content-footer-link {
  text-decoration: none;
  color: inherit;
}
.copyright{
    font-size: 15px;
    color: #98989A;
    text-align: center;
    margin: 10px 0;
}

@media screen and (max-width: 1000px) {
    .home-content{
        width: 100%;
        margin: 0;
    }
    .page-title{
        font-size: 26px;
        line-height: 40px;
        padding: 0 32px;
        margin-top: 36px;
    }
    .tab-box{
        margin: 16px 32px 72px 32px;
        padding: 6.4px 9.6px;
        border: 0.8px solid #2C2C2E;
        border-radius: 6.4px;
    }
    .tab-item{
        margin-right: 12px;
        padding: 6.4px 8px;
        border-radius: 4.8px;
        font-size: 9.6px;
    }
    .label-card-box{
        padding: 0 32px;
        flex-direction: column;
    }
    .label-card{
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 72px;
    }
    .label-card:last-child{
        margin-bottom: 27px;
    }
    .label-card-icon{
        /* width: 40px; */
        height: 45px;
    }
    .label-card-text-box{
        margin-left: 16px;
    }
    .label-title-icon{
        height: 45px;
        max-width: 100%;
    }
    .label-card-title{
        font-size: 20px;
    }
    .label-text{
        margin-top: 12px;
    }
    .app-card-box{
        padding: 0 12px;
    }
    .app-card{
        max-width: 100%;
        background: #232323;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 8px!important;
    }
    .app-icon{
        width: 48px;
    }
    .app-title{
        font-size: 20px;
    }
    .app-type{
        padding: 6.5px 9.5px;
        font-size: 11px;
    }
    .download-btn{
        display: none;
    }
    .right-arrow{
        display: block;
        cursor: pointer;
    }
    .app-desc{
        line-height: 24px;
        font-size: 14px;
    }
    .app-img{
        margin-top: 16px;
    }
    .copyright{
        font-size: 10px;
        margin-bottom: 13px;
    }
}
</style>